import React from "react";
import NavWidget from "./NavWidget";
import Icon from "@mui/icons-material/Notifications";

const UsageAlertsLink = ({ ...props }) => {
  return (
    <NavWidget
      title="Usage Alerts"
      description="Limit the potential of overusage and access charges."
      link="/usage-alerts"
      icon={<Icon />}
      {...props}
    />
  );
};

export default UsageAlertsLink;
