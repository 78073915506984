import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { addMinutes, subMinutes } from "date-fns";
import { AjaxResponse } from "../_app/api";
import { getIdentityUser } from "./api";
import { decodeJwt } from "../_app/utils";
import { useFeedbackAlerts, useQueryParam } from "../_app/hooks";
import { CONTEXT_HIERARCHY_KEY } from "../context/utils";
import { DecodedJWT } from "./types";

export function useIdentityUser(options?: any) {
  return useQuery<any, AjaxResponse>(["identityUser"], getIdentityUser, {
    ...options,
  });
}

export function useJWTAuth() {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [isLoading, setIsLoading] = useState(true);
  const jwtParam = useQueryParam("jwt");
  const ssoUrlParam = useQueryParam("sso_url");
  const tokenIssuerUrl = ssoUrlParam === window.authConfig?.tokenIssuerUrl ? ssoUrlParam : null;
  const sessionLengthMins = 20;

  const handleSuccess = useCallback(() => {
    setIsLoading(false);
    window.location.replace(window.location.origin);
  }, []);

  const setInSession = useCallback(
    (jwtEncoded: string | string[]) => {
      const jwt: DecodedJWT = decodeJwt(jwtEncoded);
      const clientId = jwt?.client_id === window.authConfig?.userPortalClientId ? jwt?.client_id : null;
      if (clientId && jwt?.tenant_ref && jwt?.sub && tokenIssuerUrl) {
        sessionStorage.setItem(
          `oidc.user:${tokenIssuerUrl}:${clientId}`,
          JSON.stringify({
            profile: jwt,
            access_token: jwtEncoded,
            token_type: "Bearer",
            scope: "openid profile roles email HUB_FE_API",
            expires_at: Math.trunc(addMinutes(new Date(), sessionLengthMins).getTime() / 1000),
          })
        );
        handleSuccess();
      } else {
        setFeedbackAlertError("Invalid JWT token");
      }
    },
    [tokenIssuerUrl, handleSuccess]
  );

  useEffect(() => {
    if (jwtParam) {
      setInSession(jwtParam);
    }
  }, [jwtParam, setInSession]);

  return { isLoading };
}

export function useJWTLogout() {
  useEffect(() => {
    const sessionKey = `oidc.user:${window.authConfig?.tokenIssuerUrl}:${window.authConfig?.userPortalClientId}`;
    const existing = JSON.parse(sessionStorage.getItem(sessionKey) || "");
    sessionStorage.setItem(
      sessionKey,
      JSON.stringify({
        ...existing,
        expires_at: Math.trunc(subMinutes(new Date(), 1).getTime() / 1000),
      })
    );
    localStorage.removeItem(CONTEXT_HIERARCHY_KEY);
    window.location.replace(window.location.origin);
  }, []);
}
