import React, { useState } from "react";
import Main from "../../_app/layouts/Main";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useMyUserUpdate } from "../hooks";
import { useIdentityUser } from "../../auth/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
  })
);

export const MyDetails = () => {
  const classes = useStyles();
  const { data: identityUser, isLoading } = useIdentityUser();
  const [requestUpdateBody, setRequestUpdateBody] = useState({});
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestUpdateBody({
      ...identityUser?.profile,
      ...requestUpdateBody,
      [event.target.name]: event.target.value,
    });
  };

  const { mutate: executeUserUpdate, isLoading: isUpdating } = useMyUserUpdate();

  const handleUpdate = () => {
    const updatedProfile: any = { ...identityUser?.profile, ...requestUpdateBody };
    if (updatedProfile?.email) {
      executeUserUpdate(updatedProfile);
    }
  };

  return (
    <Main title="My Details" data-cy="my-details-page" isLoading={isLoading || isUpdating}>
      <Grid item xs={12} md={6} data-cy="my-details-form">
        <Card elevation={1} className={classes.card}>
          <TextField
            className={classes.textField}
            name="email"
            label="Email address/Username"
            type="email"
            defaultValue={identityUser?.profile.email}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            className={classes.textField}
            name="firstName"
            label="First Name"
            type="text"
            defaultValue={identityUser?.profile.given_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            className={classes.textField}
            name="lastName"
            label="Last Name"
            type="text"
            defaultValue={identityUser?.profile.family_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            className={classes.textField}
            name="contactNumber"
            label="Contact number"
            type="phone"
            defaultValue={identityUser?.profile.phone_number}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <Button variant="contained" color="primary" onClick={handleUpdate} data-cy="update-button">
            Update
          </Button>
        </Card>
      </Grid>
    </Main>
  );
};
export default MyDetails;
