import api from "../_app/api";
import { getUser } from "./sso-api";
import { AuthConfig } from "./types";

export const getIdentityUser = async () => {
  return getUser();
};

export const getAuthConfig = async () => {
  const { data }: { data: AuthConfig } = await api({
    method: "GET",
    url: "/auth-config",
    context_injection: false,
    skip_bearer_injection: true,
    skip_context_path_injection: true,
    skip_token_refresh: true,
  });

  if (!data?.tokenIssuerUrl || !data?.hostedLoginPageDomain || !data?.userPortalClientId) {
    throw Error(`Missing some of the mandatory attributes in the returned data: ${data}`);
  }

  return data;
};
