import Main from "../../_app/layouts/Main";
import { Alert, Grid } from "@mui/material";
import { permissionCodes } from "../../permission/hooks";
import { featureFlagsMap } from "../../feature/utils";
import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { CommunicationDetail } from "../types";
import { useCommunications } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { rowParser } from "../../_app/components/Table/helpers";
import DownloadButton from "../components/DownloadButton";
import { capitalize } from "../../_app/utils/format";

export const Communications = () => {
  const classes = useStyles();
  const rowCountOptions = [10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const { data, isFetching } = useCommunications(page, rowCount);

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const parsed = useMemo(
    () =>
      data?.list.map((item: CommunicationDetail) => {
        return rowParser([
          item?.date,
          capitalize(item?.type),
          item?.subject,
          item?.recipient,
          item?.associatedProduct,
          <DownloadButton
            key={item?.communicationId}
            communicationId={item?.communicationId}
            filename={item?.filename}
          />
        ]);
      }) || [],
    [data]
  );

  return (
    <Main
      title="Communications"
      data-cy="communications-page"
      accessPermission={[permissionCodes.COMMUNICATIONS]}
      featureFlag={featureFlagsMap.COMMUNICATIONS_CENTRE}
      needSelectedAccount={false}
      isLoading={false}
    >
      <Grid className={classes.ctr}>
        <Alert severity="info" className={classes.info}>
          Find relevant communications we've sent to you here. Easily access and review important information anytime, ensuring you stay organised and never miss an update.
        </Alert>
        <div className={classes.tableCtr}>
        </div>
      </Grid>
      {isFetching ? (
        <div className={classes.loader}>
          <UILoader />
        </div>
      ) : (
        <div className={classes.ctr} data-cy="communications-table">
          <MUIDataTable
            title=""
            data={parsed}
            columns={["Date", "Type", "Subject", "Recipient", "Associated product", ""]}
            options={{
              onTableChange: handleTableChange,
              pagination: true,
              page: page,
              rowsPerPage: rowCount,
              rowsPerPageOptions: rowCountOptions,
              count: data?.total,
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              serverSide: true,
              viewColumns: false,
              sort: false,
              search: false,
              selectableRows: "none",
              rowHover: true,
              setRowProps: (row) => ({
                "data-cy": `row-id-${row?.[0]}`,
              }),
              setTableProps: () => ({ size: "small" })
            }}
          />
        </div>
      )}
    </Main>);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
      padding: "0",
      marginTop: theme.spacing(1.5)
    },
    tableCtr: {},
    info: {
      width: "fit-content",
      marginBottom: theme.spacing(3),
      alignItems: "center"
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flex: 1
    }
  })
);

export default Communications;