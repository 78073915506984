import { useMutation, useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getWdrStatus, setWdrStatus } from "./api";
import { WdrStatusResponse } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function useWdrStatus(page: number, limit: number, queries?: any[], assets?: string[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<WdrStatusResponse, AjaxResponse>(
    ["wdr-status", { page, limit, queries, assets }],
    () => getWdrStatus(page, limit, queries, assets),
    {
      ...options,
      staleTime: 0,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useSetWdrStatus(values: any, options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, any>(() => setWdrStatus(values), {
    ...options,
    onSuccess: (data) => {
      setFeedbackAlertSuccess(data);
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}
