import { Account } from "../account/types";
import { Permission } from "../permission/api";

export interface User {
  ssoId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber?: string;
  role?: string;
  userLevel: number;
  permissions?: Permission[];
  parentAccount?: string;
  state?: UserState;
  emailConfirmed?: boolean;
  accounts?: Account[];
}

export interface CognitoUser {
  ssoId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber?: string;
  emailConfirmed: boolean;
}

export enum UserState {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export interface GetUsersResponse {
  list: User[];
  page: number;
  pageSize: number;
  total: number;
}

export interface RegisterUserParams {
  accountCode: string;
  email: string;
  reCaptchaToken: string;
}
