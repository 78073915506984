import React, { useState } from "react";
import Main from "../../_app/layouts/Main";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useCognitoUserAttributes, useCognitoUserUpdate } from "../hooks";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { useValidation } from "../../form/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
  }),
);

export const MyDetails = () => {
  const classes = useStyles();
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});

  const { data, isLoading } = useCognitoUserAttributes({});
  const { mutate: executeUserUpdate, isLoading: isUpdating } = useCognitoUserUpdate();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAttributes((prevAttributes) => ({
      ...prevAttributes,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const errors = validateAll();

    if (errors.length == 0) {
      const userAttributes: AttributeType[] = Object.entries(attributes).map(([Name, Value]) => ({
        Name,
        Value,
      }));

      executeUserUpdate(userAttributes);
    }
  };

  const validationConfig: any = {
    phone_number: {
      validate: (phoneNumber: string): string | null => {
        const phoneRegex = /^\+[1-9]\d{1,14}$/;
        if (phoneNumber && !phoneNumber.match(phoneRegex)) {
          return "Please enter a valid phone number starting with '+' followed by the country code and number (e.g., +441514960453).";
        }
        return null;
      },
    },
  };

  const { validationErrors, validateAll } = useValidation(validationConfig, { ...attributes });

  return (
    <Main title="My Details" data-cy="my-details-page" isLoading={isLoading || isUpdating}>
      <Grid item xs={12} md={6} data-cy="my-details-form">
        <Card elevation={1} className={classes.card}>
          <TextField
            key={data?.email}
            disabled
            className={classes.textField}
            name="email"
            label="Email address/Username"
            type="email"
            defaultValue={data?.email}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            key={data?.firstName}
            className={classes.textField}
            name="given_name"
            label="First Name"
            type="text"
            defaultValue={data?.firstName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            key={data?.lastName}
            className={classes.textField}
            name="family_name"
            label="Last Name"
            type="text"
            defaultValue={data?.lastName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            key={data?.contactNumber}
            className={classes.textField}
            name="phone_number"
            label="Contact number"
            type="phone"
            defaultValue={data?.contactNumber}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
            error={Boolean(validationErrors.phone_number)}
            helperText={validationErrors.phone_number}
          />
          <Button variant="contained" color="primary" onClick={handleUpdate} data-cy="update-button">
            Update
          </Button>
        </Card>
      </Grid>
    </Main>
  );
};
export default MyDetails;
