import api from "../_app/api";
import { WdrStatusResponse } from "./types";

export const getWdrStatus = async (page?: number, limit?: number, queries?: any[], assets?: string[]) => {
  const { data }: { data: WdrStatusResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/wdr",
    serialize: true,
    params: { page, limit, queries, "asset-ids": assets },
  });
  return data;
};

export const setWdrStatus = async (values: any) => {
  const operations = [];
  for (let entry of values.entries()) {
    operations.push({
      assetId: entry[0],
      capped: entry[1],
    });
  }
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,
    url: "/assets/wdr",
    serialize: true,
    data: operations,
  });
  return data;
};
