import React, { cloneElement } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { UICard } from "../../../_app/components/UICard";
import { withWidget } from "../components/Widget";
import { useHistory } from "react-router-dom";

interface Props {
  icon: any;
  title: string;
  description?: string;
  link: string;
}

const NavWidget = ({ icon, title, description, link }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const styledIcon = cloneElement(icon, { className: classes.icon });
  return (
    <UICard onClick={() => history.push(link)} hasHover>
      <div className={classes.ctr}>
        {styledIcon}
        <Typography variant="h3" align="center">
          {title}
        </Typography>
        <Typography className={classes.desc}>{description}</Typography>
      </div>
    </UICard>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
  },
  icon: {
    fontSize: 55,
    color: theme.palette.primary.main,
  },
  desc: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

export default withWidget(NavWidget, "nav-widget");
