import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Main from "../../_app/layouts/Main";
import EditUserForm from "../components/EditUserForm";
import { useSingleUser, useUserUpdate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";

export const EditUser = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const { data: user, isFetching: userFetching, isSuccess: userSuccess } = useSingleUser(id);
  const { mutate: executeUserUpdate, isLoading: isUpdating } = useUserUpdate({
    onSuccess: () => {
      history.push("/users");
    }
  });

  return (
    <Main title="Edit User" data-cy="edit-user-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={userFetching}>
      {userSuccess && (
        <Card elevation={1} className={classes.card}>
          <Grid container>
            <EditUserForm
              user={user}
              isSubmitting={isUpdating}
              submitLabel="Save Changes"
              handleSubmit={executeUserUpdate}
              handleCancel={() => history.goBack()}
            />
          </Grid>
        </Card>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
    },
  })
);

export default EditUser;
