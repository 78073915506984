import React, { useEffect, useState } from "react";
import LoadingOverlay from "./components/LoadingOverlay";
import { getAuthConfig } from "../auth/api";
import { setupIdentity } from "../auth/sso-api";
import Providers from "./providers";
import { loadCspTag } from "./lib/csp";
import { getUrlHost } from "./utils";
import Routes from "./Routes";

function App() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const authConfig = await getAuthConfig();
        window.authConfig = authConfig;

        loadCspTag("default-src", [authConfig.hostedLoginPageDomain, getUrlHost(authConfig.tokenIssuerUrl)]);
        setupIdentity(authConfig.userPortalClientId, authConfig.tokenIssuerUrl);

        setIsReady(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (!isReady) return <LoadingOverlay />;
  return (
    <Providers>
      <Routes />
    </Providers>
  );
}
export default App;
