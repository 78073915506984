import React, { cloneElement, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_app/layouts/Main";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { useStore } from "../../_app/hooks";
import { useCostCentreUserLevel, useHeadUserLevel, useIndividualUserLevel } from "../../user-level/hooks";
import Advertisement from "../widgets/Advertisement";
import Welcome from "../widgets/Welcome";
import LatestBill from "../widgets/LatestBill";
import BalanceOverdue from "../widgets/BalanceOverdue";
import { useHasFeature } from "../../feature/hooks";
import Charges from "../widgets/Charges";
import ManageMobilesLink from "../widgets/nav/ManageMobilesLink";
import AssetsLink from "../widgets/nav/AssetsLink";
import HelpLink from "../widgets/nav/HelpLink";
import UsageAlertsLink from "../widgets/nav/UsageAlertsLink";
import UIGridBreak from "../../_app/components/UIGridBreak";
import { featureFlagsMap } from "../../feature/utils";
import { useUserData } from "../../user/hooks";

export const Home = () => {
  const classes = useStyles();
  const { state } = useStore();
  const { userLevel } = useUserData();
  const [viewAsLevel, setViewAsLevel] = useState(userLevel);
  const contextLevelId = state.contextHierarchy?.level?.id;
  const hasBillingManagePermission = Boolean(useHasPermission(permissionCodes.BILLING_MANAGE));
  const hasStatementsFeature = useHasFeature(featureFlagsMap.STATEMENTS);
  const hasStatementsPermission = useHasPermission(permissionCodes.BILLING_STATEMENT);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasAssetsFeature = useHasFeature(featureFlagsMap.ASSETS);
  const hasAssetsPermission = useHasPermission(permissionCodes.VIEW_ASSETS);
  const hasUsageAlertsFeature = useHasFeature(featureFlagsMap.USAGE_ALERTS);
  const hasUsageAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const hasHelpFeature = useHasFeature(featureFlagsMap.HELP);
  const hasHelpPermission = useHasPermission(permissionCodes.SUPPORT_ACCESS);

  useEffect(() => {
    setViewAsLevel(contextLevelId || userLevel);
  }, [contextLevelId, userLevel]);

  const headLevel = useHeadUserLevel();
  const costCentreLevel = useCostCentreUserLevel();
  const individualLevel = useIndividualUserLevel();

  const costCentreUser = costCentreLevel && userLevel === costCentreLevel?.id;
  const individualUser = individualLevel && userLevel === individualLevel?.id;
  const individualOrCostCentreUser = costCentreUser || individualUser;
  const groupOrCompanyUser = !individualOrCostCentreUser && headLevel && viewAsLevel && viewAsLevel < headLevel?.id;
  const accountUser = !individualOrCostCentreUser && headLevel && viewAsLevel && viewAsLevel === headLevel?.id;
  const subAccountUser = !individualOrCostCentreUser && headLevel && viewAsLevel && viewAsLevel > headLevel?.id;

  const hasWidget = (id: string) => {
    switch (id) {
      case "welcome":
        return true;
      case "advertisement":
        return true;
      case "latest-bill":
        return !groupOrCompanyUser && hasBillingManagePermission && accountUser;
      case "charges":
        return (
          !groupOrCompanyUser && hasBillingManagePermission && (accountUser || costCentreUser || individualUser || subAccountUser)
        );
      case "balance-overdue":
        return !groupOrCompanyUser && hasStatementsFeature && hasStatementsPermission && accountUser;
      case "mobile-management":
        return hasAssetsFeature && hasMobileManagementPermission;
      case "assets":
        return hasAssetsFeature && hasAssetsPermission;
      case "usage-alerts":
        return hasUsageAlertsFeature && hasUsageAlertsPermission;
      case "help":
        return hasHelpFeature && hasHelpPermission;
      default:
        return false;
    }
  };

  const renderResponsiveBlock =
    (id: string, gridPoints: number = 12, oneProps: any = {}) =>
    (...args: any) => {
      const widgets = args.filter(Boolean);
      return widgets.map((widget: any, i: number) => {
        const equal = gridPoints / widgets.length;
        const isOne = widgets.length === 1;
        const customProps = isOne && oneProps ? oneProps : {};
        const md = equal % 1 !== 0 || equal;
        const Widget = cloneElement(widget, {
          key: `${id}-${i}`,
          md,
          ...customProps,
        });
        return Widget;
      });
    };

  return (
    <Main data-cy="home-page">
      <Grid container spacing={2} className={classes.ctr}>
        <>
          {hasWidget("welcome") && <Welcome />}
          {hasWidget("advertisement") && <Advertisement />}
          <UIGridBreak />
        </>
        <>
          {hasWidget("latest-bill") && (
            <LatestBill
              userType={
                subAccountUser ? "SUB_ACCOUNT" : costCentreUser ? "COST_CENTRE" : individualUser ? "INDIVIDUAL" : undefined
              }
            />
          )}
          {renderResponsiveBlock("balance", 6, { align: "left" })(
            hasWidget("charges") && <Charges />,
            hasWidget("balance-overdue") && <BalanceOverdue />
          )}
          <UIGridBreak />
        </>
        <>
          {renderResponsiveBlock("links")(
            hasWidget("mobile-management") && <ManageMobilesLink />,
            hasWidget("assets") && <AssetsLink />,
            hasWidget("usage-alerts") && <UsageAlertsLink />,
            hasWidget("help") && <HelpLink />
          )}
          <UIGridBreak />
        </>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {},
}));

export default Home;
