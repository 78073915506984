import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useUserDisable, useUserEnable, useUserResendEmail } from "../hooks";
import UIConfirm from "../../_app/components/UIConfirm";
import { User, UserState } from "../types";

interface Props {
  user: User;
  deleteUser(user: User): void;
}

enum ButtonActions {
  Edit = "Edit",
  Disable = "Disable",
  Enable = "Enable",
  Delete = "Delete",
  ResendEmail = "Resend Welcome Email",
}

const ManageUsersActionButton = ({ user, deleteUser }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const canResendEmail = !user?.emailConfirmed;
  const [warning, setWarning] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [shouldHandleClickAway, setShouldHandleClickAway] = useState(true);
  const [currentUserActions, setCurrentUserActions] = useState<ButtonActions[]>([]);

  const { mutate: executeUserDisable } = useUserDisable();
  const { mutate: executeUserEnable } = useUserEnable();
  const { mutate: executeUserResendEmail } = useUserResendEmail();

  const constructActions = useCallback(
    (isDisabling: boolean = false) => {
      const actions = [
        ButtonActions.Edit,
        user?.state === UserState.Disabled || isDisabling ? ButtonActions.Enable : ButtonActions.Disable,
        ButtonActions.Delete,
      ];
      if (canResendEmail) {
        actions.push(ButtonActions.ResendEmail);
      }
      return actions;
    },
    [canResendEmail, user]
  );

  useEffect(() => {
    setCurrentUserActions(constructActions());
  }, [constructActions]);

  const switchDropdownVisibility = () => {
    setShouldHandleClickAway(false);
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleUserEdit = (user: User) => history.push(`/users/${user?.ssoId}`);

  const handleUserDisable = (user: User) => {
    setCurrentUserActions(constructActions(true));
    switchDropdownVisibility();
    return executeUserDisable(user);
  };

  const handleUserEnable = (user: User) => {
    setCurrentUserActions(constructActions(false));
    switchDropdownVisibility();
    return executeUserEnable(user);
  };

  const handleUserDelete = (user: User) => {
    switchDropdownVisibility();
    return deleteUser(user);
  };

  const handleResendEmail = () => {
    if (!user) return;
    executeUserResendEmail(user);
    switchDropdownVisibility();
  };

  const handleClick = (action: string) => {
    switch (action) {
      case ButtonActions.Edit:
        return handleUserEdit(user);
      case ButtonActions.Disable:
        return handleUserDisable(user);
      case ButtonActions.Enable:
        return handleUserEnable(user);
      case ButtonActions.ResendEmail:
        return handleResendEmail();
      case ButtonActions.Delete:
        return setWarning(true);
      default:
        return;
    }
  };

  return (
    <Grid ref={anchorRef} className={classes.buttonWrap}>
      <Button
        variant="outlined"
        color="primary"
        onClick={switchDropdownVisibility}
        className={classes.button}
        data-cy={`actions-button-id-${user.ssoId}`}
      >
        Actions
        {isDropdownVisible ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper open={isDropdownVisible} anchorEl={anchorRef.current} transition className={classes.popper} placement="bottom">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => (shouldHandleClickAway ? setIsDropdownVisible(false) : setShouldHandleClickAway(true))}
              >
                <MenuList id="actions-button-menu" data-cy="actions-button-menu-items">
                  {currentUserActions.map((action: string) => (
                    <MenuItem
                      key={action}
                      onClick={() => handleClick(action)}
                      className={classes.item}
                      data-cy={`${action}-action-item`}
                    >
                      {action?.toLowerCase()}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UIConfirm
        open={warning}
        setOpen={setWarning}
        onConfirm={() => {
          handleUserDelete(user);
        }}
      >
        Are you sure you want to delete{" "}
        <b>
          {user?.firstName} {user?.lastName}
        </b>{" "}
        ?
      </UIConfirm>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrap: {
      textAlign: "center",
    },
    button: {
      width: 110,
    },
    popper: {
      zIndex: 100,
      minWidth: 120,
    },
    item: {
      textTransform: "capitalize",
    },
  })
);

export default ManageUsersActionButton;
