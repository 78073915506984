import api, { IdValueQuery } from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import {
  Asset,
  GetAssetsResponse,
  GetClisResponse,
  ServiceSetting,
  GetMobileAssetsResponse,
  GetAssetsOperationHistoryResponse,
  GetBillLimitResponse,
  UpdateBillLimitParams,
  ClearBillLimitParams,
  GetBillLimitPrefsResponse,
  UpdateBillLimitPrefsParams,
  Address,
  LineTestResponse,
} from "./types";
import { Query } from "../filter/types";

export const getAssetById = async (id: string) => {
  const { data }: { data: Asset } = await api({
    method: "GET",
    context_injection: true,
    url: `/assets/${id}`,
  });
  return data;
};

export const getAssets = async (liveOnly?: boolean, page?: number, limit?: number, queries?: any[]) => {
  const { data }: { data: GetAssetsResponse } = await api({
    method: "GET",
    url: liveOnly ? "/assets/live" : "/assets",
    context_injection: true,
    serialize: true,
    params: { page, "page-size": limit, queries },
  });
  return data;
};

export const getAssetsByCostCentre = async (cc: string, page?: number, size?: number) => {
  let { data }: { data: GetAssetsResponse } = await api({
    method: "GET",
    url: `/assets/cost-centre/${cc}`,
    context_injection: true,
    params: { page, size },
  });
  return data;
};

export const getAssetsCsv = async (queries: Query[]) => {
  const params = new URLSearchParams();
  queries.forEach((q) => params.append(q.id, q.value));
  const response = await api({
    method: "GET",
    url: "/assets/export",
    context_injection: true,
    params: params,
    responseType: "arraybuffer",
  });
  return response;
};

export const getClis = async (query?: string, page: number = 0, pageSize: number = 20) => {
  const { data }: { data: GetClisResponse } = await api({
    method: "GET",
    url: "/filters/CALL_ANALYSIS/values/clis",
    context_injection: true,
    params: { "page-size": pageSize, "search-term": query, page },
  });
  return data;
};

export const performLineTest = async (cli: string) => {
  const { data }: { data: LineTestResponse } = await api({
    method: "POST",
    url: "/support/line-test",
    context_injection: true,
    params: {
      "telephone-number": cli,
    },
  });
  return data;
};

export const getServiceSettings = async (assetId: string) => {
  // @todo - replace with actual api call
  // let { data }: { data: ServiceSettings } = await api({
  //   method: "GET",
  //   context_injection: true,
  //   url: "/asset/service-settings",
  //   params: { assetId }
  // });
  const data: ServiceSetting[] = [
    {
      id: "1",
      description: "Bundle Notification",
      value: "Opted In",
    },
    {
      id: "2",
      description: "Worldwide Data Roaming",
      value: "Uncapped",
    },
    {
      id: "3",
      description: "Last Call Date",
      value: "2021-02-01",
    },
    {
      id: "4",
      description: "Divert on Busy",
      value: "447665577889",
    },
    {
      id: "5",
      description: "Divert Immediately",
      value: "447665577889",
    },
    {
      id: "5",
      description: "Divert No Reply",
      value: "447665577889",
    },
    {
      id: "6",
      description: "Divert Not Reachable",
      value: "447665577889",
    },
    {
      id: "7",
      description: "4G Allowed",
      value: "YES",
    },
    {
      id: "8",
      description: "5G Service",
      value: "YES",
    },
    {
      id: "9",
      description: "Conference Calling value",
      value: "YES",
    },
    {
      id: "10",
      description: "Voicemail",
      value: "YES",
    },
    {
      id: "11",
      description: "Wifi Calling",
      value: "YES",
    },
    {
      id: "12",
      description: "MMS Service",
      value: "NO",
    },
  ];
  return data;
};

export const getServiceDetails = async (assetId: string, page: number, limit: number) => {
  // @todo - replace with actual api call
  // let { data }: { data: any } = await api({
  //   method: "GET",
  //   context_injection: true,
  //   url: "/asset/service",
  //   params: { assetId, page, "page-size": limit }
  // });
  const data = {
    list: [
      {
        id: "123",
        description: "Desc 1",
        startDate: "2021-02-02",
        endDate: "2021-04-02",
        quantity: "5",
        frequency: "Monthly",
        cost: "£20",
      },
      {
        id: "456",
        description: "Desc 2",
        startDate: "2021-01-01",
        endDate: "2021-06-06",
        quantity: "3",
        frequency: "Monthly",
        cost: "£10",
      },
      {
        id: "789",
        description: "Desc 3",
        startDate: "2021-04-04",
        endDate: "2021-06-10",
        quantity: "1",
        frequency: "Monthly",
        cost: "£5",
      },
    ],
    page: 1,
    pageSize: 25,
    total: 10,
  };
  return data;
};

export const getMobileAssets = async (page?: number, limit?: number, queries?: any[], clis?: string, eligible?: boolean) => {
  const { data }: { data: GetMobileAssetsResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/mobile",
    serialize: true,
    params: {
      "include-bill-limit-eligibility": eligible,
      page,
      "page-size": limit,
      queries,
      clis,
    },
  });
  return data;
};

export const getLineAssetClis = async (page?: number, limit?: number, query?: string) => {
  const { data }: { data: GetClisResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/line",
    serialize: true,
    params: { page, "page-size": limit, query },
  });
  return data;
};

export const orderSim = async (assetId: string, address: Address, simType: string) => {
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/assets/${assetId}/sim/order`,
    context_injection: true,
    data: {
      deliveryAddress: address,
      simType: simType,
    },
  });
  return data;
};

export const getMobileNetworks = async (assetIds?: string[], queries?: IdValueQuery[]) => {
  const { data }: { data: string[] } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/mobile/network",
    serialize: true,
    params: { assetIds, queries },
  });
  return Array.from(new Set(data?.filter(Boolean)));
};

export const getAssetsOperationHistory = async (startingToken: string | undefined, limit: number) => {
  const { data }: { data: GetAssetsOperationHistoryResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/mobile-management/history",
    params: {
      "page-size": limit,
      "starting-token": startingToken,
    },
  });
  return data;
};

export const getBillLimit = async (assetIds: string[], page?: number, limit?: number, queries?: any[]) => {
  const { data }: { data: GetBillLimitResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/bill-limit",
    serialize: true,
    params: { page, "page-size": limit, queries, "asset-ids": assetIds },
  });
  return data;
};

export const updateBillLimit = async (params: UpdateBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/assets/bill-limit`,
    context_injection: true,
    data: parsedParams,
  });
  return data;
};

export const clearBillLimit = async (params: ClearBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: boolean } = await api({
    method: "DELETE",
    url: `/assets/bill-limit`,
    context_injection: true,
    data: parsedParams,
  });

  return data;
};

export const getBillLimitPrefs = async (assetId?: string) => {
  const { data }: { data: GetBillLimitPrefsResponse } = await api({
    method: "GET",
    context_injection: true,
    url: `/assets/bill-limit/notification-prefs`,
    params: {
      "asset-id": assetId,
    },
  });
  return data;
};

export const updateBillLimitPrefs = async (params: UpdateBillLimitPrefsParams) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,
    url: `/assets/bill-limit/notification-prefs`,
    data: params,
  });
  return data;
};

export const updateTag = async ({ assetId, tag }: { assetId: string; tag: string }) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,
    url: `/assets/${assetId}/tag`,
    params: { tag },
  });
  return data;
};
