import { Alert, AlertTitle, Button, Link, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useBrandName, useLoginBackground, useLogo, usePoweredByLogo } from "../../brand/hooks";
import { useUserRegister } from "../hooks";
import { genericError, getAlias } from "../../_app/utils/text";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Public from "../../_app/layouts/Public";
import withRecaptcha from "../../_app/providers/RecaptchaProvider";

const Register = () => {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const classes = useStyles();
  const brandName = getAlias(useBrandName());
  const logo = useLogo(true);
  const loginBg = useLoginBackground();
  const poweredByLogo = usePoweredByLogo();

  const [email, setEmail] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [notMatch, setNotMatch] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { mutate: executeRegister, error, isSuccess, isError } = useUserRegister();

  // reCaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();
  const performRecaptchaVerification = useCallback(() => {
    if (!executeRecaptcha) throw Error("Recaptcha not initiated");
    return executeRecaptcha("register_user");
  }, [executeRecaptcha]);

  const handleRegister = async () => {
    setNotMatch(false);
    setEmailExists(false);
    setConfirmed(false);
    try {
      const reCaptchaToken = await performRecaptchaVerification();
      if (reCaptchaToken) {
        executeRegister({ accountCode, email, reCaptchaToken });
      } else throw Error("Recaptcha not initiated");
    } catch (error) {
      setFeedbackAlertError("Unable to verify human status");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setNotMatch(false);
      setEmailExists(false);
      setConfirmed(true);
    } else if (isError) {
      switch (error?.status) {
        case 404: {
          setNotMatch(true);
          setEmailExists(false);
          setConfirmed(false);
          break;
        }
        case 409: {
          setNotMatch(false);
          setEmailExists(true);
          setConfirmed(false);
          break;
        }
        default: {
          setFeedbackAlertError(error?.statusText || error?.data?.message || genericError());
        }
      }
    }
  }, [error, isSuccess, isError]);

  return (
    <Public data-cy="register-page">
      <div className={classes.container}>
        <div className={classes.left}>
          <img className={classes.bg} src={loginBg} alt="register page background" />
          <div className={classes.textWrap}>
            <Typography variant="h1">Welcome to your new {brandName} Portal</Typography>
            <Typography variant="h3">Meeting your business' voice, connectivity, mobile, phone system and cloud needs</Typography>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.pageCtr}>
            <div className={classes.pageWrap}>
              <div className={classes.contentWrap}>
                <div
                  className={classes.logo}
                  style={{
                    background: `url('${logo}') center / contain no-repeat`,
                  }}
                />
                {!notMatch && !emailExists && !confirmed && (
                  <Alert severity="info" className={classes.alert}>
                    Please enter your account number and the email address that receives the monthly bill. For security reasons,
                    we can only register this email, however once registered, you can create additional users.
                  </Alert>
                )}
                {notMatch && (
                  <Alert severity="error" className={classes.alert}>
                    Your account number and/or email address do not match our records. Please check the details and retry. If the
                    problem persists, please contact customer support.
                  </Alert>
                )}
                {emailExists && (
                  <Alert severity="error" className={classes.alert}>
                    A user already exists for this email address. Please try to Reset your password.
                  </Alert>
                )}
                {confirmed && (
                  <Alert severity="success" className={classes.alert}>
                    <AlertTitle className={classes.successTitle}>Thank you for registration</AlertTitle>
                    An email has been sent. Please check your spam folder if you do not find the email in your inbox. Click{" "}
                    <Link href="/">here</Link> to go to login page.
                  </Alert>
                )}
                {!confirmed && (
                  <>
                    <TextField
                      id="account"
                      name="account"
                      label="Account Number"
                      value={accountCode || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => setAccountCode(e.target.value)}
                    />
                    <TextField
                      id="email"
                      name="email"
                      label="Email Address"
                      type="email"
                      value={email || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                      className={classes.register}
                      variant="contained"
                      color="primary"
                      onClick={handleRegister}
                      disabled={Boolean(!accountCode || !email)}
                    >
                      Register
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className={classes.footer}>
              <img className={classes.footerLogo} src={poweredByLogo} alt="powered by hub logo" />
            </div>
          </div>
        </div>
      </div>
    </Public>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100vh",
      padding: 0,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    left: {
      minWidth: "50%",
      flex: 1.33,
      [theme.breakpoints.down("md")]: {
        flex: 0.5,
        minWidth: "40%",
      },
      [theme.breakpoints.down("sm")]: {
        maxHeight: 150,
      },
    },
    bg: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    right: {
      display: "flex",
      flex: 1,
      overflowY: "auto",
      minWidth: "300px",
    },
    textWrap: {
      position: "absolute",
      display: "flex",
      width: "50%",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      top: 0,
      color: "#fff",
      padding: "5% 0 5% 5%",
      [theme.breakpoints.down("md")]: {
        width: "36%",
        fontSize: 26,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "& > h1": {
        marginBottom: "0.5rem",
        fontSize: 42,
      },
      "& > h3": {
        display: "block",
        fontSize: 26,
      },
    },
    pageCtr: {
      flex: 1,
      padding: "5% 12%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
    },
    pageWrap: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    contentWrap: {
      flex: 1,
      flexDirection: "column",
      gap: "25px",
      padding: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      width: "200px",
      height: "110px",
    },
    alert: {
      alignItems: "center",
    },
    successTitle: {
      fontWeight: "bold",
      marginBottom: "5px",
    },
    register: {
      width: "45%",
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "20px",
    },
    footerLogo: {
      height: "100px",
    },
  };
});

export default withRecaptcha(Register);
