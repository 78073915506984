import React from "react";
import NavWidget from "./NavWidget";
import Icon from "@mui/icons-material/ContactSupport";

const HelpLink = ({ ...props }) => {
  return (
    <NavWidget
      title="Need help?"
      description="Our help section contains helpful guides and information."
      link="/help"
      icon={<Icon />}
      {...props}
    />
  );
};

export default HelpLink;
