import React, { useState } from "react";
import { Grid, Box, Typography, Theme, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ClearIcon from "@mui/icons-material/Clear";
import UIButton from "../../_app/components/UIButton";

interface Props {
  title: string;
  value: string;
  editable?: boolean;
  isLoading?: boolean;
  action?: JSX.Element;
  onUpdate?: any;
  onCancel?: any;
  children?: JSX.Element;
}

export const DetailItem = ({
  title,
  value,
  editable = false,
  isLoading = false,
  action,
  onUpdate,
  onCancel,
  children,
}: Props) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleUpdate = () => {
    onUpdate();
    setIsEditing(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsEditing(false);
  };

  return (
    <>
      <Grid item xs={5} className={classes.gridItem}>
        <Typography variant="caption" data-cy="attr-key" className={classes.title}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={7} className={classes.gridItem}>
        <>
          {isEditing && <>{Boolean(children) && children}</>}
          {!isEditing && (
            <>
              {isLoading && <CircularProgress color="inherit" size={18} />}
              {!isLoading && (
                <Typography variant="subtitle2" component="span" data-cy="attr-val" className={classes.value}>
                  {value}
                </Typography>
              )}
            </>
          )}
          {Boolean(action) && (
            <Box display="flex" justifyContent="flex-end">
              {action}
            </Box>
          )}
          {editable && (
            <Box display="flex" justifyContent="flex-end">
              {isEditing ? (
                <Box display="flex">
                  <UIButton data-cy="cancel-btn" isIconButton color="primary" onClick={handleCancel}>
                    <ClearIcon />
                  </UIButton>
                  <UIButton
                    data-cy="save-btn"
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    className={classes.saveButton}
                  >
                    Save
                  </UIButton>
                </Box>
              ) : (
                <UIButton
                  data-cy="edit-btn"
                  variant="text"
                  color="primary"
                  className={classes.button}
                  onClick={() => setIsEditing(true)}
                  disabled={isLoading}
                >
                  Edit
                </UIButton>
              )}
            </Box>
          )}
        </>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: `${theme.palette.grey[400]}`,
    },
    gridItem: {
      height: "47px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:not(:nth-last-child(-n+2))": {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      },
    },
    value: {
      maxHeight: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    button: {
      justifyContent: "flex-end",
    },
    saveButton: {
      margin: "auto",
      height: "30px",
      marginRight: "8px",
    },
  })
);

export default DetailItem;
