import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import Tabbed from "../../_app/layouts/Tabbed";
import UIBack from "../../_app/components/UIBack";
import BarringDetails from "../../bars/components/BarringDetails";
import Details from "../components/Details";
import { useSingleAsset } from "../hooks";
import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes, useHasPermission } from "../../permission/hooks";

export const AssetDetails = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: asset, isFetching } = useSingleAsset(id);
  const [selectedTab, setSelectedTab] = useState("details");
  const hasMobileBarsFeature = useHasFeature(featureFlagsMap.MOBILE_BARS);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasBars = Boolean(hasMobileBarsFeature && hasMobileManagementPermission && asset?.assetType === "MOBILE");

  const onTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setSelectedTab(value);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "details":
        return <Details showBars={() => setSelectedTab("barring")} hasBars={hasBars} />;
      case "barring":
        return <BarringDetails assetId={asset?.id} />;
      default:
        return null;
    }
  };

  return (
    <Main
      title="Asset Details"
      data-cy="asset-details-page"
      isLoading={isFetching}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.VIEW_ASSETS}
    >
      <Box width="100%">
        <UIBack btnProps={{ className: classes.back }} />
        <Tabbed
          selectedTab={selectedTab}
          tabs={[
            { value: "details", label: "Details", testId: "details-tab" },
            { value: "barring", label: "Barring", testId: "barring-tab", hidden: !hasBars },
          ]}
          handleTabChange={onTabChange}
        >
          <div className={classes.wrap}>{renderContent()}</div>
        </Tabbed>
      </Box>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tabList: {
      paddingBottom: theme.spacing(4),
    },
    wrap: {
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  })
);

export default AssetDetails;
