import api from "../_app/api";
import { UserLevel, UserLevelFlag } from "./types";

export const getAllUserLevels = async () => {
  const { data }: { data: UserLevel[] } = await api({
    method: "GET",
    url: "/user-levels",
    context_injection: true,
  });
  const parsed = data.map((ul) => {
    if (ul.flag?.startsWith("COST_CENTER")) {
      ul.flag = UserLevelFlag.CostCentre;
      return ul;
    }
    return ul;
  });
  window.userLevels = parsed;
  return parsed;
};
