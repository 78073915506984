import React from "react";
import { withWidget } from "./components/Widget";
import { UICard } from "../../_app/components/UICard";
import { Button } from "@mui/material";
import { useTheme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { usePromotion } from "../../brand/hooks";
import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";

const Advertisement = () => {
  const classes = useStyles();
  const theme = useTheme();
  const promotion = usePromotion();
  const hasFeature = useHasFeature(featureFlagsMap.ADVERT);

  if (!hasFeature || !promotion) return null;
  return (
    <UICard
      padding="10px"
      height="191px"
      style={
        promotion.image && {
          backgroundImage: `url(${promotion.image})`,
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "0%",
          minHeight: "fit-content",
        }
      }
    >
      <div className={classes.ctr}>
        {Boolean(promotion.title) && (
          <Typography variant="h1" color="textPrimary" className={`${classes.shadow} ${classes.item}`}>
            {promotion.title}
          </Typography>
        )}
        {Boolean(promotion.subtitle) && (
          <Typography
            variant="h3"
            color="textPrimary"
            className={`${classes.shadow} ${classes.item}`}
            style={{ color: theme.palette.grey[500] }}
          >
            {promotion.subtitle}
          </Typography>
        )}
        {Boolean(promotion.linkUrl) && (
          <Button
            variant="outlined"
            size="small"
            className={`${classes.btn} ${classes.item}`}
            onClick={() => window.open(promotion.linkUrl)}
          >
            {promotion.linkTitle || "View"}
          </Button>
        )}
        <div />
      </div>
    </UICard>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "100%",
    paddingRight: "20px",
    zIndex: 20,
  },
  item: {
    marginTop: "auto",
  },
  btn: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  shadow: {
    textShadow: `0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white`,
  },
}));

export default withWidget(Advertisement, "advertisement");
