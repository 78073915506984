import { useQuery } from "react-query";
import { getCommunications, getDocumentPresignedUrl } from "./api";
import { useFeedbackAlerts } from "../_app/hooks";

export function useCommunications(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery(
    ["communications", { page, limit }],
    () => getCommunications(page, limit),
    {
      ...options,
      onError: () => {
        setFeedbackAlertError("Could not fetch communications.");
      }
    }
  );
}


export function useDocumentDownload(communicationId: string, options = {}) {
  return useQuery(
    ["document-download"],
    () => getDocumentPresignedUrl(communicationId),
    { ...options }
  );
}