import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  assignCostCentres,
  createCostCentre,
  deleteCostCentre,
  getAccounts,
  getBillingAddress,
  getBillSettings,
  getCostCentres,
  getFilteredCostCentres,
  getMyAccounts,
  updateBillingAddress,
  updateBillSettings,
  updateCostCentre
} from "./api";
import {
  BillingAddress,
  BillingAddressUpdateParams,
  BillSettings,
  CostCentre,
  CostCentreAssignParams,
  FilterCostCentresResponse,
  GetAccountsParams,
  GetAccountsResponse,
  Group
} from "./types";
import { costCentreFilters } from "./filterConfig";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";

export function useBillingAddress(options = {}) {
  return useQuery<BillingAddress, AjaxResponse>(["billingAddress"], getBillingAddress, {
    ...options,
  });
}

export function useBillingAddressUpdate(options = {}) {
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess, setFeedbackAlertError} = useFeedbackAlerts();

  return useMutation<BillingAddress, AjaxResponse, BillingAddressUpdateParams>((address) => updateBillingAddress(address), {
    onSuccess: (data) => {
      queryClient.setQueryData(["billingAddress"], () => data);
      setFeedbackAlertSuccess("Billing address changed.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    ...options,
  });
}

export function useBillSettings(options = {}) {
  return useQuery<BillSettings, AjaxResponse>(["billSettings"], getBillSettings, {
    ...options,
  });
}

export function useBillSettingsUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<BillSettings, AjaxResponse, BillSettings>((billSettings: BillSettings) => updateBillSettings(billSettings), {
    onSuccess: (response, body: BillSettings) => {
      queryClient.setQueryData(["billSettings"], () => body);
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    ...options,
  });
}

export function usePaperBillCharge() {
  const unit = "£";
  const value = "7.50";
  const parsed = `${unit}${value}`;
  return { unit, value, parsed };
}

export function useMyAccounts(options = {}) {
  return useQuery<Group[], AjaxResponse>(["myAccounts"], getMyAccounts, {
    ...options,
  });
}

export function useAccountsForParent(params: GetAccountsParams, options = {}) {
  return useInfiniteQuery<GetAccountsResponse>(
    ["accountsForParent", JSON?.stringify(params || {})],
    ({ pageParam = 0 }) => {
      return getAccounts({
        tree: true,
        offset: pageParam,
        ...params,
      });
    },
    {
      getNextPageParam: ({ page, pageSize }: any) => (page + 1) * pageSize,
      ...options,
    }
  );
}

/* COST CENTRES START */

export function useCostCentres(options = {}) {
  return useQuery<CostCentre[], AjaxResponse>(["costCentres"], getCostCentres, {
    ...options,
  });
}

export function useFilteredCostCentres(search: string, options = {}) {
  return useQuery<FilterCostCentresResponse, AjaxResponse>(
    ["filtered-cost-centres", search],
    () => getFilteredCostCentres({ search }),
    { ...options }
  );
}

export function useCostCentreUpdate(options = {}) {
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess, setFeedbackAlertError} = useFeedbackAlerts();

  return useMutation<CostCentre, AjaxResponse, CostCentre>((update: CostCentre) => updateCostCentre(update), {
    onSuccess: (updated: CostCentre) => {
      queryClient.setQueryData(["costCentres"], (list: any) => {
        const mutateOld = (old: any) => {
          old.forEach(function (cc: any, i: number) {
            if (cc.id === updated.id) {
              //@ts-ignore
              this[i] = updated;
            } else if (cc.subCostCentres.length) {
              mutateOld(cc.subCostCentres);
            }
          }, old);
        };
        mutateOld(list);
        return list;
      });

      setFeedbackAlertSuccess("Cost Centre updated");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentreCreate(options = {}) {
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess, setFeedbackAlertError} = useFeedbackAlerts();

  return useMutation<CostCentre, AjaxResponse, CostCentre>((cc: CostCentre) => createCostCentre(cc), {
    onSuccess: (updated: any) => {
      queryClient.setQueryData(["costCentres"], (list: any) => {
        const mutateOld = (old: any) => {
          if (updated.parent === null) {
            old.push(updated);
          } else {
            old.forEach(function (cc: any, i: number) {
              if (cc.id === updated.parent) {
                //@ts-ignore
                this[i].subCostCentres.push(updated);
              } else if (cc.subCostCentres.length) {
                mutateOld(cc.subCostCentres);
              }
            }, old);
          }
        };

        mutateOld(list);
        return list;
      });

      setFeedbackAlertSuccess("Cost Centre added");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentreDelete(options = {}) {
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess, setFeedbackAlertError} = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, string>((id: string) => deleteCostCentre(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["costCentres"]);
      queryClient.invalidateQueries(["assets"]);
      queryClient.invalidateQueries(["assets-live"]);
      setFeedbackAlertSuccess("Cost Centre deleted");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentresAssign(
  params?: { page: number; limit: number; queries?: any[]; clis?: string[] },
  options: any = {},
  refetchCCs: boolean = true
) {
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess, setFeedbackAlertError} = useFeedbackAlerts();

  return useMutation<CostCentreAssignParams, AjaxResponse, any>(assignCostCentres, {
    ...options,
    onSuccess: (data) => {
      if (typeof options?.onSuccess === "function") options?.onSuccess?.();
      if (refetchCCs) queryClient.invalidateQueries(["costCentres"]);
      queryClient.invalidateQueries(["assets-live"]);
      queryClient.invalidateQueries(["assets", params]);
      setFeedbackAlertSuccess(data);
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    }
  });
}

export function useCostCentresFilters() {
  return { data: costCentreFilters };
}

/* COST CENTRES END */
