import { Brand } from "./types";
import affinityLogo from "../_app/images/logo-affinity.png";
import affinityLogoDark from "../_app/images/logo-affinity-dark.jpeg";

export const DEFAULT_BRAND: Brand = {
  id: "my-account-default-id",
  name: "MyAccount",
  logo: affinityLogo,
  logoDark: affinityLogoDark,
  loginBg:
    "https://aurora-sso-aurora-live-asset.s3.eu-west-2.amazonaws.com/content/images/4441CA00-6F81-4649-9F72-04DED8B9CF0C/brand_bg.jpeg",
  poweredByImg: "https://aurora-sso-aurora-uat-asset.s3.eu-west-2.amazonaws.com/content/images/aurora/footer_logo.png",
  primary: "#7a5aff",
  secondary: "#4ec4b0",
  tertiary: "#524b65",
};
