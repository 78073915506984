import React, { useEffect, useMemo, useState } from "react";
import Main from "../../_app/layouts/Main";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FiltersCard from "../../filter/components/FiltersCard";
import { Alert, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { useAllFilters, useFilterQueries } from "../../filter/hooks";
import { useDeleteAlerts, useUsageAlerts } from "../hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import { IdValueQuery } from "../../_app/api";
import UsageAlertsTabBar from "../components/UsageAlerts/UsageAlertsTabBar";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import UIConfirm from "../../_app/components/UIConfirm";
import { humanize } from "../../_app/utils/format";
import { rowParser } from "../../_app/components/Table/helpers";
import SelectAllHeader from "../../_app/components/Table/SelectAllHeader";
import UILoader from "../../_app/components/UILoader";
import { UsageAlert } from "../types";
import { RequestStatusLabel } from "../utils";

const ALERTS_FILTERS = "ALERTS";

export interface SelectedParams {
  alerts?: string[];
  filters?: IdValueQuery[];
  from?: string;
}

export interface SelectedRows {
  index?: string;
  dataIndex?: string;
}

export const UsageAlerts = () => {
  const classes = useStyles();
  const history = useHistory();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const queries = useFilterQueries();

  const { data: usageAlerts, isFetching, fetchNextPage, hasNextPage } = useUsageAlerts(rowCount, queries);
  const parsed = useMemo(() => {
    const pages: any = usageAlerts?.pages?.map((page) => {
      return page?.list?.map((item: UsageAlert) =>
        rowParser([
          item.id,
          item.cli || "All numbers on " + item.accountCode,
          humanize(item.type),
          humanize(item.product),
          item.description,
          item.alertAction?.bar?.value,
          item.alertAction?.contacts
            ?.map((c: any) => {
              return `${c.forename} ${c.surname}`;
            })
            .join(", "),
          RequestStatusLabel[item.requestStatus],
          <Button
            variant="text"
            color="primary"
            data-cy="edit"
            disabled={item?.requestStatus?.includes("PENDING")}
            onClick={() => {
              history.push(`/usage-alerts/${item.id}`);
            }}
          >
            Edit
          </Button>,
        ])
      );
    });
    return [].concat.apply([], pages);
  }, [usageAlerts, history]);

  const { mutate: deleteAlerts } = useDeleteAlerts();
  const handleDeleteAction = () => {
    const { alerts } = getSelectedParams();
    if (alerts) {
      deleteAlerts(alerts);
    }
  };

  const getSelectedParams = (): SelectedParams => {
    const selectedAlerts = selectedRows.reduce((acc: any[], r) => {
      if (parsed?.[r]) acc.push(parsed?.[r]?.[0]);
      return acc;
    }, []);
    return {
      filters: selectAll ? queries : undefined,
      alerts: selectedAlerts,
      from: "Usage Alerts",
    };
  };

  const handleTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      case "rowSelectionChange":
        setSelectAll(false);
        setTimeout(() => {
          setSelectedRows(state.selectedRows.data?.map((r: SelectedRows) => r?.index));
        });
        break;
    }
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(parsed.map((_, i) => i));
    } else {
      setSelectedRows([]);
    }
  }, [selectAll, parsed]);

  return (
    <Main
      title={
        <>
          Usage Alerts
          <Button
            variant="contained"
            color="primary"
            data-cy="add-usage-alert-button"
            onClick={() => history.push("/usage-alerts/new")}
            startIcon={<AddIcon />}
          >
            Add Alert
          </Button>
        </>
      }
      data-cy="support-usage-alerts"
      featureFlag={featureFlagsMap.USAGE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount
    >
      <div className={classes.wrap}>
        <Alert severity="info" className={classes.info}>
          Alerts and bars are a great way to limit the potential of overusage and excess charges. Usage alerts will notify you
          when a user has reached the limit you have set. This can be for calls (mobile and fixed line), data or SMS.
        </Alert>
        <Alert severity="info" className={classes.info}>
          An autobar can be selected for Usage Alerts which will restrict the use of a mobile number. This can be used to assist
          in management of costs & reduce unexpected "bill shock". We will use reasonable endeavours to apply the autobar, as soon
          as we become aware that the mobile number has breached the usage alert amount. However, in certain circumstances that
          are beyond our control, such as delays in call data being received from the network, overall charges may exceed the set
          limit and are still liable to be paid for under your terms & conditions.
        </Alert>
        <UsageAlertsTabBar selectedTab="usage-alerts">
          <FiltersCard fetchHook={useAllFilters} hasReset={true} hasDownload={false} usage={ALERTS_FILTERS} />
          <SelectAllHeader
            onChange={() => setSelectAll(!selectAll)}
            disabled={isFetching}
            value={selectAll}
            selectedRows={selectedRows || []}
          >
            <Button
              variant="outlined"
              size="small"
              color="primary"
              data-cy="delete-usage-alerts-button"
              onClick={() => setShowConfirm(true)}
              disabled={!selectedRows.length}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            <UIConfirm
              open={showConfirm}
              setOpen={setShowConfirm}
              onConfirm={() => {
                handleDeleteAction();
              }}
            >
              Are you sure you want to delete selected usage alerts?
            </UIConfirm>
          </SelectAllHeader>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div data-cy="usage-alerts-table" className={classes.tableCtr}>
              <MUIDataTable
                title=""
                data={parsed}
                columns={[
                  {
                    name: "",
                    options: {
                      display: "false",
                    },
                  },
                  "Identifier",
                  "Alert Type",
                  "Product",
                  "Description",
                  "Action",
                  "Recipients",
                  "Status",
                  "",
                ]}
                options={{
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  onTableChange: handleTableChange,
                  rowsSelected: selectedRows,
                  pagination: false,
                  customFooter: () => {
                    return <LoadMoreFooter disabled={isFetching} hasMore={Boolean(hasNextPage)} onChangePage={fetchNextPage} />;
                  },
                  serverSide: true,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  rowHover: true,
                  selectableRowsHeader: false,
                  isRowSelectable: (dataIndex: number) => !(parsed[dataIndex]?.[7] as string)?.includes?.("Pending"),
                  setTableProps: () => ({ size: "small" }),
                  setRowProps: (row) => ({
                    "data-cy": `usage-alert-id-${row?.[0]}`,
                  }),
                }}
              />
            </div>
          )}
        </UsageAlertsTabBar>
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      flexDirection: "column",
      width: "100%",
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    tableCtr: {
      marginTop: 0.5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& div:first-child": {
        marginTop: 0,
      },
    },
    info: {
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  })
);

export default UsageAlerts;
