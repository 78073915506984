import React from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Main from "../../_app/layouts/Main";
import EditUserForm from "../components/EditUserForm";
import { useUserCreate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { User } from "../types";

export const AddUser = () => {
  const history = useHistory();
  const classes = useStyles();
  const { mutate: executeUserCreate, error: createError, isLoading } = useUserCreate({
    onSuccess: () => {
      history.push("/users");
    },
  });

  const addUser = (user: User) => {
    executeUserCreate(user);
  };

  const user = createError?.config?.data ? { ...JSON.parse(createError.config.data), email: null } : {};

  return (
    <Main title="Add User" data-cy="add-user-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isLoading}>
      <Card elevation={1} className={classes.card}>
        <Grid container>
          <EditUserForm
            user={user}
            newUser={true}
            hasEmailConfirmation
            isSubmitting={isLoading}
            handleSubmit={addUser}
            handleCancel={() => history.goBack()}
          />
        </Grid>
      </Card>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
    },
  })
);

export default AddUser;
