import React from "react";
import { Chip, Tooltip, TooltipProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import FaceIcon from "@mui/icons-material/Face";
import { useIdentityUser } from "../../../auth/hooks";
import { format } from "date-fns";

interface Props {
  className?: string;
  lite?: boolean;
  placement?: TooltipProps["placement"];
}

const MasqueradingChip = ({ lite, placement = "top", className }: Props) => {
  const classes = useStyles();
  const { data: identityUser, isFetching } = useIdentityUser();
  const isMasquerading = Boolean(identityUser?.profile?.act);

  if (isFetching || !isMasquerading) return null;

  const sessionEnding = format(new Date(identityUser?.expires_at * 1000), "HH:mm aaaaa'm'");
  return (
    <Tooltip
      title={`${lite ? "Masquerading session" : "Session"} ends at ${sessionEnding}`}
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
    >
      <Chip
        icon={<FaceIcon />}
        color="primary"
        label={lite ? "" : "Masquerading"}
        className={`${classes.chip} ${lite && classes.lite} ${className}`}
        data-cy="masquerading-user-chip"
        size="small"
      />
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      display: "flex",
      margin: theme.spacing(1),
      marginLeft: "auto",
      marginRight: "auto",
      width: "fit-content",
    },
    lite: {
      paddingRight: 7,
      "& > span": {
        display: "none",
      },
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: "12px",
      fontWeight: "bold",
    },
  })
);

export default MasqueradingChip;
