import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Alert, Box, Grid, TableSortLabel } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MUIDataTable from "mui-datatables";
import { getUsageAnalysisFile, getUsageAnalysisSummaryFile } from "../api";
import { Query, UsersFilter, UsersFilterValue } from "../../filter/types";
import Main from "../../_app/layouts/Main";
import ManageBillingTabBar from "../../billing/components/ManageBillingTabBar";
import { useDeleteFilter, useFilterValues, useSaveFilters, useUserFilters } from "../../filter/hooks";
import { useGetUsageAnalysis, useGetUsageAnalysisSupportedColumns } from "../hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { parseColumns, pushValueToRow } from "../components/helpers";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import { downloadFile, extractFilenameFromHeaders, uniq } from "../../_app/utils";
import useUsageAnalysisSummaryData from "../components/UsageAnalysisSummary";
import { useUserLevelMap } from "../../user-level/hooks";
import { useUsageAnalysisFilters } from "../../billing/hooks";
import FiltersCard from "../../filter/components/FiltersCard";
import { useHasFeature } from "../../feature/hooks";
import { genericError } from "../../_app/utils/text";
import { rowParser, SortParam } from "../../_app/components/Table/helpers";
import Tabbed from "../../_app/layouts/Tabbed";
import UILoader from "../../_app/components/UILoader";
import { UsageAnalysisColumn } from "../types";
import { featureFlagsMap } from "../../feature/utils";
import { filtersIdMap } from "../../filter/utils";
import { UNBILLED } from "../../billing/utils";
import { USAGE_BILL_PERIODS_ENDPOINT } from "../../billing/filterConfig";

const USAGE: string = "CALL_ANALYSIS";

const DefaultSorting = {
  ALL: [
    { col: "DATE", dir: "asc" },
    { col: "TIME", dir: "asc" },
  ] as SortParam[],
  CLI: [{ col: "CLI", dir: "desc" }] as SortParam[],
  ACCOUNT: [{ col: "TOTAL_PRICE", dir: "desc" }] as SortParam[],
  COST_CENTRE: [{ col: "COST_CENTRE", dir: "desc" }] as SortParam[],
};

type TableType = keyof typeof DefaultSorting;

export const UsageAnalysis = () => {
  const classes = useStyles();
  const selectedTab = "usage";
  const { search } = useLocation();
  const history = useHistory();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [applied, setApplied] = useState(true);
  const rowCount = 25;
  const [sort, setSort] = useState<SortParam[]>(DefaultSorting.ALL);
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const userLevels = useUserLevelMap();
  const queryClient = useQueryClient();
  const hasCostCentre = useHasFeature(featureFlagsMap.BILLING_COST_CENTRE_SUMMARY);
  const hasPermissionToDownload = Boolean(useHasPermission(permissionCodes.BILLING_DOWNLOAD));

  const { data: periodFilterValues, isFetching: isFilterValuesFetching } = useFilterValues(USAGE_BILL_PERIODS_ENDPOINT);

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      if (key === filtersIdMap.BILL_PERIOD && value === UNBILLED) {
        periodFilterValues?.pages[0]?.forEach((period: string) => {
          if (period.includes("UNBILLED")) {
            filters.push({
              id: key,
              value: period,
            });
          }
        });
      } else {
        values.forEach((query) => {
          filters.push({
            id: key,
            value: query,
          });
        });
      }
    });
    return filters;
  }, [queryParams, periodFilterValues]);

  // Always apply unbilled filter
  useEffect(() => {
    const params = getParams();
    const periodParams = params?.filter((p) => p?.id === filtersIdMap?.BILL_PERIOD);

    if (queryParams.get(filtersIdMap.BILL_PERIOD) === UNBILLED) queryParams.delete(filtersIdMap.BILL_PERIOD);

    if (!isFilterValuesFetching) {
      const values = periodFilterValues?.pages[0] ?? [];
      if (!periodParams.length && values.length) {
        const periods = values.some((item) => item.includes("UNBILLED"))
          ? values.filter((period) => period.includes("UNBILLED"))
          : [values[0]];
        queryParams.append(filtersIdMap?.BILL_PERIOD, periods.join(","));
      }
    }

    const removeParams: string[] = [];
    queryParams.forEach((value, key) => {
      if (value === "" || value === undefined || value === null) {
        removeParams.push(key);
      }
    });
    removeParams.forEach((key) => {
      queryParams.delete(key);
    });

    if (history.location.search.replace("?", "") !== queryParams.toString()) {
      history.replace({ search: queryParams.toString() });
    }
  }, [getParams, history, periodFilterValues, queryParams, isFilterValuesFetching]);

  const tableNamesMap: any = {
    ALL: "Itemisation View",
    CLI: "CLI/Identifier Summary",
    ACCOUNT: `${userLevels?.[30]?.name} Summary`,
    COST_CENTRE: `${userLevels?.[40]?.name} Summary`,
  };

  let tableTypes = ["ALL", "CLI", "ACCOUNT"];
  if (hasCostCentre) {
    tableTypes = ["ALL", "CLI", "ACCOUNT", "COST_CENTRE"];
  }

  const [tableType, setTableType] = useState<TableType>("ALL");

  useEffect(() => {
    setSort(DefaultSorting[tableType]);
  }, [tableType]);

  const onApply = useCallback(() => {
    setApplied(true);

    if (tableType === "ALL") {
      queryClient.resetQueries("usageAnalysis");
    } else {
      queryClient.resetQueries("usageAnalysisSummary");
      queryClient.resetQueries("usageAnalysisSummaryTotal");
    }
  }, [tableType, queryClient]);

  // Cols Persistence
  const persistenceKey = (type: string) => {
    return `${USAGE}_${type}_COLUMNS`;
  };

  const persistColumnConfig = useCallback(
    (colCode: string, visible: boolean) => {
      if (colCode) {
        const saved = JSON.parse(localStorage.getItem(persistenceKey(tableType)) || "{}");
        localStorage.setItem(
          persistenceKey(tableType),
          JSON.stringify({
            ...saved,
            [colCode]: { visible },
          })
        );
      }
    },
    [tableType]
  );

  // Supported Cols - fetch all supported cols
  const { data: supportedCols, isFetching: isFetchingSupportedCols } = useGetUsageAnalysisSupportedColumns(tableType);

  const customHeader = useCallback(
    (label: string, name: string, sortable: boolean) => {
      const isInactive = sort[1]?.col !== name;
      const sortDirection = isInactive || !sort[1]?.dir ? "desc" : sort[1]?.dir;
      if (sort[0].col !== name && sortable)
        return (
          <Box display="flex">
            {label}
            <span className={classes.labelWrap}>
              <TableSortLabel active direction={sortDirection} className={isInactive ? classes.inactiveLabel : ""} />
            </span>
          </Box>
        );
      else return <>{label}</>;
    },
    [classes.inactiveLabel, classes.labelWrap, sort]
  );

  // Table Cols - apply col display params
  let tableColumns: any = useMemo(() => {
    return {
      ALL: parseColumns(supportedCols.ALL, persistenceKey("ALL"), customHeader),
      CLI: parseColumns(supportedCols.CLI, persistenceKey("CLI"), customHeader),
      ACCOUNT: parseColumns(supportedCols.ACCOUNT, persistenceKey("ACCOUNT"), customHeader),
      COST_CENTRE: parseColumns(supportedCols.COST_CENTRE, persistenceKey("COST_CENTRE"), customHeader),
    };
  }, [supportedCols, customHeader]);

  // Fetch Cols - track col list for table endpoint
  const [fetchColumns, setFetchColumns] = useState<string[]>([]);
  useEffect(() => {
    if (!isFetchingSupportedCols) {
      setFetchColumns(tableColumns[tableType].filter((c: any) => c?.options?.display).map((c: any) => c.name));
    }
    // eslint-disable-next-line
  }, [tableType, isFetchingSupportedCols]);

  useEffect(() => {
    setDirtyColumns(null);
  }, [fetchColumns, onApply]);

  // Cols Changes - track col changes in bulk
  const [dirtyColumns, setDirtyColumns] = useState<{
    [key: string]: boolean;
  } | null>(null);

  const handleColumnsChange = (colCode: string, action: string) => {
    if (action) {
      persistColumnConfig(colCode, action === "add");
      setDirtyColumns((current) => ({
        ...current,
        [colCode]: action === "add",
      }));
    }
  };

  const applyColumnsChanges = useCallback(() => {
    if (dirtyColumns)
      setFetchColumns((current) => {
        return uniq(
          [...current, ...Object.keys(dirtyColumns)].filter((code) =>
            typeof dirtyColumns[code] === "boolean" ? dirtyColumns[code] : true
          )
        );
      });
  }, [dirtyColumns]);

  useEffect(() => {
    const el = document.querySelector("#root");
    el?.addEventListener("focusin", applyColumnsChanges);
    return () => {
      el?.removeEventListener("focusin", applyColumnsChanges);
    };
  }, [applyColumnsChanges]);

  // Table Data & Interactions
  const {
    data: usageAnalysisResponse,
    isLoading: isAnalysisLoading,
    isFetching: isAnalysisFetching,
    isError,
    hasNextPage: hasNextUsageAnalysisPage,
    fetchNextPage,
    isFetchingNextPage: analysisIsFetchingNextPage,
  } = useGetUsageAnalysis(rowCount, sort, getParams(), {
    enabled: Boolean(fetchColumns?.length && applied && tableType === "ALL" && getParams().length && !isFetchingSupportedCols),
  });

  const usageSummary: any = useUsageAnalysisSummaryData({
    tableTypes,
    tableType,
    rowCount,
    sort,
    getParams,
    fetchColumns,
    applied,
    setTableType,
    setApplied,
  });

  let data: any = [];

  if (applied && !isAnalysisLoading && !usageSummary?.isLoading && fetchColumns?.length && getParams().length) {
    setApplied(false);
  }

  if (tableType === "ALL") {
    data = [];
    usageAnalysisResponse?.pages?.forEach((page) => {
      page.list?.forEach((ca: any) => {
        const row = [] as any;
        supportedCols.ALL?.forEach((column: UsageAnalysisColumn) => {
          pushValueToRow(row, ca, column, setApplied, tableType, setTableType);
        });
        data.push(rowParser(row));
      });
    });
  } else {
    data = rowParser(usageSummary?.data || []);
  }

  const pageChange = () => {
    if (tableType === "ALL") fetchNextPage();
    else usageSummary.fetchNextPageSummary();
  };

  useEffect(() => setApplied(true), [sort]);

  const showLoader =
    isAnalysisLoading ||
    (isAnalysisFetching && !analysisIsFetchingNextPage) ||
    (usageSummary?.isFetching && !usageSummary?.isFetchingNextPage);

  const nextPageTableType = () => {
    if (tableType === "ALL") {
      return hasNextUsageAnalysisPage;
    } else {
      return usageSummary?.hasNextPage;
    }
  };

  const isFetchingTableType = () => {
    if (tableType === "ALL") {
      return analysisIsFetchingNextPage;
    } else {
      return usageSummary?.isFetchingNextPage;
    }
  };

  // Filters
  const { mutate: executeCreateFilter, reset: resetCreateOperation } = useSaveFilters(USAGE);
  const { mutate: executeDeleteFilter, reset: resetDeleteOperation } = useDeleteFilter(USAGE);

  const saveFilters = (name: string) => {
    let filters: UsersFilter = { name: name, filters: [] };
    queryParams.forEach((value, key) => {
      const filter: UsersFilterValue = {
        filterId: key,
        values: value.split(","),
      };
      filters.filters.push(filter);
    });
    resetDeleteOperation();
    executeCreateFilter(filters);
  };

  const deleteFilter = (usersFilter: UsersFilter) => {
    resetCreateOperation();
    executeDeleteFilter(usersFilter?.id);
  };

  const addUsersFilter = (usersFilter: UsersFilter) => {
    const params: string[] = [];
    queryParams.forEach((value, key) => {
      // TODO: preffix check?
      params.push(key);
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });

    usersFilter?.filters?.forEach((filter) => {
      let value = "";
      filter?.values?.forEach((v) => {
        value = value === "" ? v : value + "," + v;
      });
      queryParams.append(filter?.filterId, value);
    });
    history.replace({
      search: queryParams.toString(),
    });
    setApplied(true);
    onApply();
  };

  const handleDownload = async (selectedType: string) => {
    const isAll = tableType === "ALL";
    if (isAll && showLoader) return;
    try {
      const response: any = isAll ? await fetchAllFile(selectedType) : await fetchSummaryFile();

      const filename = extractFilenameFromHeaders(response) || `usage-analysis${isAll ? "" : "-summary"}-export.csv`;
      downloadFile(response?.data, filename);
    } catch (err) {
      setFeedbackAlertError((err as AxiosResponse).data?.message || genericError());
    }
  };

  const fetchAllFile = async (selectedType: string) => {
    const params = getParams() || [];
    return getUsageAnalysisFile(selectedType, params, sort);
  };

  const fetchSummaryFile = async () => {
    const params = getParams() || [];
    return getUsageAnalysisSummaryFile(tableType, params, sort);
  };

  const renderNoMatch = () => {
    if (isError) {
      return <Alert severity="error">Data failed to load.</Alert>;
    } else if (isAnalysisLoading || isAnalysisFetching || usageSummary?.isLoading) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    } else return "Sorry, no matching records found";
  };

  return (
    <Main
      title="Bill Explorer"
      data-cy="usage-analysis-page"
      accessPermission={permissionCodes.BILLING_MANAGE}
      needSelectedAccount={true}
    >
      <ManageBillingTabBar selectedTab={selectedTab}>
        <Grid item>
          <FiltersCard
            title=""
            fetchHook={useUsageAnalysisFilters}
            onApply={onApply}
            myFilters={{
              fetchHook: useUserFilters,
              deleteHook: deleteFilter,
              saveHook: saveFilters,
              addHook: addUsersFilter,
            }}
            usage={USAGE}
            downloadFileType="CSV"
            disableApply={!queryParams.has(filtersIdMap?.BILL_PERIOD)}
            hasDownload={hasPermissionToDownload}
            handleDownloadClick={handleDownload}
          />
        </Grid>
        <Grid item className={classes.bottomCtr}>
          <Tabbed
            selectedTab={tableType}
            tabs={tableTypes.map((type: string) => ({
              value: type,
              label: tableNamesMap[type],
            }))}
            handleTabChange={(ev: any, value: any) => {
              setTableType(value);
            }}
          />
        </Grid>
        <div className={classes.tableCtr}>
          <MUIDataTable
            key={tableType}
            title="Usage"
            data={data}
            columns={tableColumns[tableType] || []}
            options={{
              serverSide: true,
              onColumnViewChange: handleColumnsChange,
              onColumnSortChange: (col, dir) => setSort([{ col, dir }]),
              rowsPerPage: rowCount,
              customFooter: () => {
                return (
                  <LoadMoreFooter hasMore={nextPageTableType()} disabled={isFetchingTableType()} onChangePage={pageChange} />
                );
              },
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: true,
              sortOrder: {
                name: sort[0].col ?? "",
                direction: sort[0].dir ?? "desc",
              },
              search: false,
              selectableRows: "none",
              rowHover: true,
              setTableProps: () => ({
                size: "small",
              }),
              viewColumns: tableType !== "ALL",
              textLabels: {
                body: {
                  noMatch: renderNoMatch(),
                },
              },
              tableBodyHeight: "50vh",
            }}
          />
        </div>
      </ManageBillingTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      margin: "40px 0",
    },
    bottomCtr: {
      marginTop: theme.spacing(3),
    },
    tableCtr: {
      '& div[class*="MuiToolbar-root"]': {
        borderRadius: "0px",
      },
      '& div[class*="MUIDataTableFilterList-root-"]': {
        display: "none",
      },
      "& .MuiTableSortLabel-icon": {
        "&:not(.inactiveLabel)": {
          color: theme.palette.primary.main,
        },
      },
      '& button[class*="MUIDataTableHeadCell-toolButton"]': {
        marginRight: 0,
      },
    },
    labelWrap: {
      marginBottom: "2px",
    },
    inactiveLabel: {
      "& .MuiTableSortLabel-icon": {
        color: `${theme.palette.grey[200]} !important`,
      },
    },
  })
);

export default UsageAnalysis;
