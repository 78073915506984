import React, { useCallback, useEffect, useState } from "react";
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from "@mui/material";
import { SimplePaletteColorOptions, Theme } from "@mui/material/styles";
import { themeConfig } from "./theme";
import { createTheme, lighten, darken } from "@mui/material/styles";
import actions from "../../store/Actions";
import { useStore } from "../../hooks";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useIdentityUser } from "../../../auth/hooks";
import { getBrand } from "../../../brand/api";
import { useBrandColors } from "../../../brand/hooks";
import { Brand } from "../../../brand/types";
import { DEFAULT_BRAND } from "../../../brand/utils";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
  }
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export default function ThemeProvider({ children }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const { state, dispatch } = useStore();
  const { data: user } = useIdentityUser();
  const parentContextId = state.contextHierarchy?.lastParentId;
  const brandedTheme = useBrandedTheme();

  const setMetaTags = (brand: Brand) => {
    const $ = (query: string): any => document.querySelector(query);
    const desc: HTMLMetaElement | null = $('meta[name="description"]');
    const ogTitle: HTMLMetaElement | null = $('meta[property="og:title"]');
    const ogImage: HTMLMetaElement | null = $('meta[property="og:image"]');
    const appleIcon: HTMLLinkElement | null = $('link[rel="apple-touch-icon"]');
    const favicon: HTMLLinkElement | null = $('link[rel="icon"]');

    const title = brand?.name || DEFAULT_BRAND.name;
    const image = brand?.favicon || brand?.logo || "";
    if (desc) desc.content = title;
    if (ogTitle) ogTitle.content = title;
    if (ogImage) ogImage.content = image;
    if (appleIcon) appleIcon.href = image;
    if (favicon) favicon.href = image;
  };

  const setBrand = useCallback(
    (resp?: any) => {
      if (resp?.primary) {
        dispatch({ type: actions.SET_BRAND, payload: resp });
      }
      setMetaTags(resp);
      setIsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    setIsLoading(true);
    setBrand();
  }, [setBrand]);

  useEffect(() => {
    let isUnmounted = false;
    setIsLoading(true);
    getBrand()
      .then((resp) => {
        if (isUnmounted) return;
        setBrand(resp);
      })
      .catch((resp) => {
        if (isUnmounted) return;
        setBrand(resp);
      });
    return () => {
      isUnmounted = true;
    };
  }, [parentContextId, setBrand, user]);

  if (isLoading) return <LoadingOverlay />;
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={brandedTheme}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export function useBrandedTheme() {
  const colors = useBrandColors();
  const textLight = "#fff";
  const textDark = "#282828";

  const brandedPalette = {
    ...themeConfig?.palette,
    primary: {
      main: colors?.primary,
      light: lighten(colors?.primary, 0.5),
      dark: darken(colors?.primary, 0.2),
      contrastText: colors?.primary_text === "dark" ? textDark : textLight,
    },
    secondary: {
      main: colors?.secondary,
      light: lighten(colors?.secondary, 0.5),
      dark: darken(colors?.secondary, 0.2),
      contrastText: colors?.secondary_text === "dark" ? textDark : textLight,
    },
    tertiary: {
      main: colors?.tertiary,
      light: lighten(colors?.tertiary, 0.5),
      dark: darken(colors?.tertiary, 0.35),
      contrastText: colors?.tertiary_text === "dark" ? textDark : textLight,
    },
  };

  const components = {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover": {
            color: brandedPalette?.primary?.contrastText,
            backgroundColor: brandedPalette?.primary?.main,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          "& button.Mui-disabled": {
            opacity: 0.5,
          },
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& .datatables-noprint": {
            margin: "100px 0",
            marginLeft: "40%",
            maxWidth: "fit-content",
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          "& [aria-label='View Columns']": {
            "&:after": {
              content: '"Columns"',
              fontSize: 14,
              position: "relative",
              left: "5px",
              color: brandedPalette?.primary?.main,
              paddingRight: "5px",
            },
          },
          "& button": {
            color: brandedPalette?.primary?.main,
            padding: "5px 10px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: brandedPalette?.primary?.light,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: any }) => ({
          ...(ownerState.severity === "success" &&
            ownerState.variant === "standard" && {
              backgroundColor: (brandedPalette?.success as SimplePaletteColorOptions)?.light,
              color: (brandedPalette?.success as SimplePaletteColorOptions)?.dark,
              ".MuiAlert-icon": {
                color: (brandedPalette.success as SimplePaletteColorOptions)?.main,
              },
            }),
        }),
      },
    },
  };

  return createTheme({
    ...themeConfig,
    palette: brandedPalette,
    components: {
      ...themeConfig.components,
      ...components,
    },
  });
}
