import React from "react";
import NavWidget from "./NavWidget";
import Icon from "@mui/icons-material/FilterBAndW";

const AssetsLink = ({ ...props }) => {
  return (
    <NavWidget
      title="Assets"
      description="View information about services you have with us."
      link="/assets"
      icon={<Icon />}
      {...props}
    />
  );
};

export default AssetsLink;
