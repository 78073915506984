import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TabPanel from "@mui/lab/TabPanel";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import ManageUsersActionsButton from "../components/ManageUsersActionsButton";
import { useAllUsers, useUserDelete } from "../hooks";
import { useAllRoles } from "../../role/hooks";
import { permissionCodes } from "../../permission/hooks";
import { useAllUserLevels } from "../../user-level/hooks";
import Tooltip from "@mui/material/Tooltip";
import { getAssignedAccountsCodeList, getAssignedAccountsCodeNameList } from "../utils";
import UILoader from "../../_app/components/UILoader";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import ManageUsersTabBar from "../components/ManageUsersTabBar";
import { getIsTopLevelContext } from "../../context/utils";
import { User, UserState } from "../types";

export interface TableConfig {
  title: string;
  columns: MUIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "Users",
  columns: ["First Name", "Last Name", "Email", "Role", "User Level", "User Access", { label: " ", name: "Actions" }],
};

export const ManageUsers = () => {
  const classes = useStyles();
  const history = useHistory();
  const selectedTab = "users";
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const { data: users, isFetching: isUsersFetching, isError: isUsersError } = useAllUsers(page, rowCount);
  const { data: roles, isFetching: isRolesFetching, isError: isRolesError } = useAllRoles();
  const { data: levels, isFetching: isLevelsFetching, isError: isLevelsError } = useAllUserLevels();

  const { mutate: executeUserDelete } = useUserDelete();

  useEffect(() => {
    setPage(users?.page || 0);
  }, [users]);

  const columns = tableConfig.columns;
  const parsed =
    users?.list.map((u: User) => {
      return [
        u.firstName,
        u.lastName,
        <Tooltip title={u.emailConfirmed ? "Confirmed" : "Unconfirmed"} placement="left" arrow>
          <div>{u.email}</div>
        </Tooltip>,
        roles?.list.find((role) => role.id === u.role)?.name,
        levels?.find((level) => level.id === u.userLevel)?.name,
        <Tooltip
          title={<span className={classes.accessTooltip}>{getAssignedAccountsCodeNameList(u, undefined, "\n") || ""}</span>}
          placement="left-start"
          arrow
        >
          <div>
            {getAssignedAccountsCodeList(u, 3) || ""}
            {(u?.accounts?.length || 0) > 3 && "..."}
          </div>
        </Tooltip>,
        <ManageUsersActionsButton user={u} deleteUser={executeUserDelete} />,
        u?.state === UserState.Disabled,
      ];
    }) || [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const onTableScroll = (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
    ev.currentTarget.click();
  };

  const isTopLevel = getIsTopLevelContext();

  return (
    <Main
      title={
        <>
          Manage Users
          <Button
            variant="contained"
            color="primary"
            data-cy="add-user-btn"
            startIcon={<AddIcon />}
            onClick={() => history.push("/user/new")}
          >
            <span className={classes.addBtnText}>Add User</span>
          </Button>
        </>
      }
      data-cy="manage-users-page"
      accessPermission={permissionCodes.USERS_MANAGE}
    >
      <ManageUsersTabBar selectedTab={selectedTab} showRoles={!isTopLevel}>
        <TabPanel className={classes.ctr} value={selectedTab}>
          {(isUsersFetching || isRolesFetching || isLevelsFetching) && !isUsersError && !isRolesError && !isLevelsError ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr} data-cy="users-table" onScroll={onTableScroll}>
              <MUIDataTable
                title={tableConfig.title}
                data={parsed}
                columns={columns || []}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: users?.total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  sort: false,
                  search: false,
                  serverSide: true,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({ size: "small" }),
                  viewColumns: false,
                  setRowProps: (row) => {
                    return {
                      className: row[7] && classes.disabledRow,
                      "data-cy": `user-id-${row[6].props.user.ssoId}`,
                    };
                  },
                }}
              />
            </div>
          )}
        </TabPanel>
      </ManageUsersTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      padding: "0",
    },
    tableCtr: {
      "& div:nth-child(3)": {
        padding: "0px",
      },
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    disabledRow: {
      color: theme.palette.text.disabled,
    },
    accessTooltip: {
      whiteSpace: "pre-wrap",
    },
    addBtnText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default ManageUsers;
