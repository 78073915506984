import { DisabledReason } from "./api";

export const formatDisabledReason = (reason: DisabledReason | null): string => {
  switch (reason) {
    case DisabledReason.ByRole:
      return "Disabled by role";
    case DisabledReason.ByLevel:
      return "Disabled by user level";
    default:
      return reason || "";
  }
};
