import api from "../_app/api";
import { GetCommunicationsResponse } from "./types";

export const getCommunications = async (page: number, limit: number) => {
  const { data }: { data: GetCommunicationsResponse } = await api({
    method: "GET",
    url: "/communications",
    context_injection: true,
    params: { page: page, "size": limit }
  });
  return data;
};

export const getDocumentPresignedUrl = async (communicationId: string) => {
  const { data }: { data: string } = await api({
    method: "GET",
    url: `/communications/${communicationId}/file`,
    context_injection: true,
  });
  return data;
};