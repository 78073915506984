import { ContextHierarchy } from "../_app/providers/HierarchyContextProvider";

export const CONTEXT_HIERARCHY_KEY = "CONTEXT_HIERARCHY";

export function getCachedContextHierarchy(): ContextHierarchy {
  return JSON?.parse(localStorage?.getItem(CONTEXT_HIERARCHY_KEY) || "null");
}
export function getContextPath(ctx?: ContextHierarchy): string {
  const context = ctx || getCachedContextHierarchy();
  return (context?.parentContext ? getContextPath(context.parentContext) + ":" : "") + (context?.id ? context?.id : "");
}

export function getLastParent(context: Partial<ContextHierarchy> | null): Partial<ContextHierarchy> | null {
  if (!context?.parentContext?.id) return context;
  return getLastParent(context?.parentContext);
}

export function getIsTopLevelContext(): boolean {
  const context = getCachedContextHierarchy();
  return context?.id === context?.lastParentId;
}
