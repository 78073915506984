import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  createUser,
  deleteUser,
  disableUser,
  doesHubUserExist,
  enableUser,
  fetchUserAttributes,
  getAccountsForLevel,
  getAllUsers,
  getUserById,
  registerUser,
  resendWelcomeEmail,
  updateCognitoUser,
  updateMyUser,
  updateUser,
} from "./api";
import { getAllUserLevels } from "../user-level/api";
import { CognitoUser, GetUsersResponse, RegisterUserParams, User } from "./types";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { GetUserCommandOutput, UpdateUserAttributesCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import { mapToCognitoUser } from "./utils";
import { useFeedbackAlerts, useStore } from "../_app/hooks";
import { getLastParent } from "../context/utils";

export function useUserRegister(options = {}) {
  return useMutation<any, AjaxResponse, RegisterUserParams>(
    (user: RegisterUserParams) => registerUser(user.accountCode, user.email, user.reCaptchaToken),
    options,
  );
}

export function useHubUserExist(options = {}) {
  return useQuery<boolean, AjaxResponse>(["hubUserExist"], doesHubUserExist, options);
}

export function useAllUsers(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetUsersResponse, AjaxResponse>(
    ["users", { page, limit }],
    async () => {
      const users = await getAllUsers(page, limit);
      const levels = await getAllUserLevels();
      users?.list.forEach((u) => {
        u.accounts?.forEach((a) => {
          a.level = levels.find((l) => l.id === a.level.id) || a.level;
        });
      });
      return users;
    },
    {
      keepPreviousData: true,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message || "Failed to fetch users");
      },
      ...options,
    },
  );
}

export function useSingleUser(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<User, AjaxResponse>(["user", id], () => getUserById(id), {
    ...options,
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserCreate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, User>((user: User) => createUser(user), {
    ...options,
    onSuccess: (data: User, variables: User) => {
      queryClient.setQueryData(["user", variables?.ssoId], () => variables);
      queryClient.invalidateQueries(["users"]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("User created");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserUpdate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, any>((user) => updateUser(user), {
    ...options,
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(["user", data?.ssoId]);
      queryClient.invalidateQueries(["users"]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("User updated");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useMyUserUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, any>((user) => updateMyUser(user), {
    onSuccess: (data: User) => {
      queryClient.setQueryData(["myUser"], () => data);
      setFeedbackAlertSuccess("User updated");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCognitoUserAttributes(options = {}) {
  return useQuery<GetUserCommandOutput, Error, CognitoUser>(["userAttributes"], () => fetchUserAttributes(), {
    select: (data) => mapToCognitoUser(data.UserAttributes),
    ...options,
  });
}

export function useCognitoUserUpdate(options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const queryClient = useQueryClient();

  return useMutation<UpdateUserAttributesCommandOutput, Error, any>(
    (attributes: AttributeType[]) => updateCognitoUser(attributes),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["userAttributes"]);
        setFeedbackAlertSuccess("User updated");
      },
      onError: () => {
        setFeedbackAlertError("Could not update user");
      },
    },
  );
}

export function useUserDelete(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, User>((user: User) => deleteUser(user), {
    ...options,
    onSuccess: (data: User) => {
      queryClient.invalidateQueries(["user", data?.ssoId]);
      queryClient.invalidateQueries(["users"]);
      setFeedbackAlertSuccess("User deleted");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserDisable(options = {}) {
  const queryClient = useQueryClient();
  return useMutation<User, AjaxResponse, User>((user: User) => disableUser(user), {
    onSuccess: (data: User) => {
      queryClient.invalidateQueries(["user", data?.ssoId]);
      queryClient.invalidateQueries(["users"]);
    },
    ...options,
  });
}

export function useUserEnable(options = {}) {
  const queryClient = useQueryClient();
  return useMutation<User, AjaxResponse, User>((user: User) => enableUser(user), {
    onSuccess: (data: User) => {
      queryClient.invalidateQueries(["user", data?.ssoId]);
      queryClient.invalidateQueries(["users"]);
    },
    ...options,
  });
}

export function useUserResendEmail(options = {}) {
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, User>((user: User) => resendWelcomeEmail(user), {
    ...options,
    onSuccess: () => {
      setFeedbackAlertSuccess("Welcome email sent");
    },
  });
}

export function useAccountsForLevel(level: string, params: any, options = {}) {
  return useInfiniteQuery<any, AjaxResponse>(
    ["accountsForLevel", level, JSON?.stringify(params || {})],
    ({ pageParam = 0 }) => {
      if (level !== undefined || params?.parent !== "0") {
        return getAccountsForLevel(level, {
          offset: pageParam,
          ...params,
        });
      } else {
        return [];
      }
    },
    {
      getNextPageParam: (lastPage: any) => {
        const limit = lastPage?.pageSize * lastPage?.page + 1;
        return lastPage?.page < limit / lastPage?.pageSize && Boolean(lastPage?.list?.length)
          ? lastPage.pageSize + lastPage.page * limit
          : undefined;
      },
      ...options,
    },
  );
}

export const useUserData = () => {
  const { state } = useStore();
  const userAccounts = getLastParent(state.contextHierarchy)?.userAccessibleAccounts ?? [];
  const userLevel = userAccounts?.[0]?.level?.id ?? 0;
  return { userAccounts, userLevel };
};
